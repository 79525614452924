import {memo} from "react";

const CancelIcon = ({ onClick }) => {
    return (
        <div onClick={onClick} className={'cursor-pointer'}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="18" fill="#7C764C"/>
                <line x1="12" y1="23.2929" x2="22.2929" y2="13" stroke="white" strokeLinecap="round"/>
                <line x1="12.7071" y1="13" x2="23" y2="23.2929" stroke="white" strokeLinecap="round"/>
            </svg>
        </div>
    )
}
export default memo(CancelIcon)