import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {isEmptyObject} from "@/app/utils/validation";

export const useCartHook = ({cookieData}) => {
    const [cart, setCart] = useState({})
    const storeCart = useSelector(state => state.cart.cart)
    useEffect(() => {
        if(isEmptyObject(cookieData) && isEmptyObject(storeCart)) return
        const value = isEmptyObject(storeCart) ? JSON.parse(cookieData.value) : storeCart
        setCart(value)

    }, [cookieData,storeCart])
    return {cart}
}