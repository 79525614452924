'use client'
import { createContext } from "react";

const CartModalContext = createContext({
    isCartModalOpen: false,
    setIsCartModalOpen: (value) => { },
    reload: false,
    setReload: (value) => {}
})
export default CartModalContext
