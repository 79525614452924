import {memo} from "react";

const CancelIconGray = ({ onClick }) => {
    return (
        <div onClick={onClick} className={'cursor-pointer'}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 3.5L3.5 16.5" stroke="#353E5C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.5 3.5L16.5 16.5" stroke="#353E5C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
}
export default memo(CancelIconGray)