export const addToCart = async (data) => {
    const result = await fetch('/api/cart', {
        method: 'POST',
        body: JSON.stringify(data)
    })
    return await result.json()
}

export const getCart = async () => {
    // const res = await fetch(`/api/cart`)
    // if (!res.ok) {
    //     throw new Error("Failed to fetch data");
    // }
    // return res.json()
    try {
        const res = await fetch(`/api/cart`)
        if (!res.ok) {
            throw new Error('Failed to fetch');
        }
        return res.json()
    } catch (error) {
        console.error("Error >", error)
    }
}

export const deleteCartItem = async (id, variationId) => {
    let url = `/api/cart?id=${id}`;

    if (variationId) {
        url += `&variationId=${variationId}`;
    }

    const result = await fetch(url, {
        method: 'DELETE'
    });

    return await result.json();
};

export const emptyCart = async () => {
    const result = await fetch(`/api/cart`, {
        method: 'DELETE'
    })
    return await result.json()
}