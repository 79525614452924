'use client'
import React, {useEffect, useRef, useState} from 'react';
import { useRouter } from "next/navigation";
import { useCartHook } from "@/app/hooks/useCartHook";
import CancelIconGray from "@/app/components/icons/CancelIconGray";
import CustomNumberFormat from "@/app/components/common/CustomNumberFormat";
import {addToCart, deleteCartItem, getCart} from "@/app/services/CartService";
import {useDispatch} from "react-redux";
import {updateCart} from "@/app/slices/cartSlice";
import {getProducts} from "@/app/services/ProductService";
import CancelIcon from "@/app/components/icons/CancelIcon";

import "@/app/styles/global.scss";
import { Inter } from "next/font/google";
import useWindowSize from "@/app/hooks/useWindowSize";
const inter = Inter({ subsets: ["latin"] });

const CartPopUpModal = ({setCartProductCount, isOpen, setIsCartModalOpen, onClose, data, reload, setReload }) => {

    const modalRef = useRef();
    const router = useRouter();
    const { cart } = useCartHook({ cookieData: data.cookieCart });
    const [products, setProducts] = useState([])
    const dispatch = useDispatch();
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [isAnimating, setIsAnimating] = useState(false);
    const {isMobile} = useWindowSize();


    const calculateDiscount = (newProduct) => {
        const sum = Object.values(cart).reduce((acc, currentValue) => {
            return acc + currentValue[0].quantity;
        }, 0);
        const totalPrice = calculateSubtotal(newProduct)
         if(sum === 2){
            return totalPrice * 5/100
        } else if(2 < sum && sum <= 4){
            return totalPrice * 10/100
        } else if(sum >= 5){
            return totalPrice * 15/100
        }else
            return 0
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => setIsAnimating(true), 10); // Dodaje malo kašnjenje kako bi se osiguralo da je modal vidljiv pre animacije
        } else {
            setIsAnimating(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if(products.length === 0){
            setReload(false)
        }
    },[products])

    useEffect(() => {
        getCart().then(response => {
            if (response) {
                const ids = Object.keys(response)
                if (ids.length === 0) {
                    setProducts([])
                    setDiscount(0)
                    return
                }
                if (ids.length) {
                    setProducts(data.products.filter(product => cart[product.id]))
                    setDiscount(calculateDiscount(data.products.filter(product => cart[product.id])))
                }
            }
        })
    }, [cart, reload])

    // useEffect(() => {
    //     const fetchCart = async () => {
    //         try {
    //             const response = await getCart();
    //             if (response) {
    //                 const ids = Object.keys(response);
    //                 if (ids.length === 0) {
    //                     setProducts([]);
    //                     setDiscount(0);
    //                     return;
    //                 }
    //                 const filteredProducts = data.products.filter(product => cart[product.id]);
    //                 setProducts(filteredProducts);
    //                 setDiscount(calculateDiscount(filteredProducts));
    //             }
    //         } catch (error) {
    //             console.error('Error fetching cart data:', error);
    //         }
    //     };
    //
    //     fetchCart();
    // }, [cart, reload]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    if (!isOpen) return null;

    const handeCashDesk = () => {
        setIsCartModalOpen(false);
        router.push('/checkout?state=1');
    };


    function calculateTotal() {
        const subtotal = products.reduce((acc, product) => {
            const quantity = cart[product.id] ? cart[product.id][0]?.quantity : 0;
            const price = product?.price || 0;
            return acc + (price * quantity);
        }, 0);

        return subtotal - discount + deliveryPrice;
    }

    function calculateSubtotal(newProducts) {
        return newProducts.reduce((acc, product) => {
            const quantity = cart[product.id][0]?.quantity || 0;
            const price = product?.price || 0;
            return acc + (price * quantity);
        }, 0);
    }

    const handleQuantityUpdate = (id, quantity) => {
        addToCart({
            [id]:[ { quantity: parseInt(quantity.toString(),10) } ]
        }).then(response => {
            dispatch(updateCart({data: response, uuid: 'content'}))
        });
    };

    const handleRemoveItem = (id) => {
        deleteCartItem(id).then(response => {
            dispatch(updateCart({data: response, uuid: 'content delete'}))
            if (products.length === 1) {
                setProducts([]);
                setCartProductCount(0)
                return;
            }
        })
    }

    const handleGoToStore = () => {
        setIsCartModalOpen(false)
        router.push('/prodavnica')
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-10 z-[999] flex justify-end">
            <div ref={modalRef}
                 className={`relative z-[40] lg:w-[420px] xl:w-[440px] 2xl:w-[460px] 3xl:w-[470px] bg-[#F7F4ED] h-full mt-[20px] md:mt-[0px] overflow-auto py-4 px-[30px] rounded-tl-[200px] transform transition-transform duration-500 ease-out ${isAnimating ? 'translate-x-0' : 'translate-x-full'}`}>
                {products.length > 0 ?
                <div className={'h-full flex flex-col justify-evenly'}>
                   <div onClick={() => setIsCartModalOpen(false)} className={'flex justify-end mb-[100px] z-50'}>
                       <CancelIcon/>
                   </div>
                    <div className={'flex flex-col h-[100%] justify-between'}>
                    <div className={'flex flex-col'}>
                        {products.map((product, index) => (
                            <div key={index} className={'flex flex-row justify-center my-4'}>
                                <div className="relative h-[96px] w-1/3 max-w-[100px]">
                                    <img className={'bg-white rounded-[10px] p-4'} width={isMobile ? '85px' : '95px'} height={isMobile ? '85px' : '95px'} src={product?.images[0]?.src} alt={product.name} />
                                </div>
                                <div className={'flex flex-col w-2/3 px-[10px] md:px-3 max-w-[190px] md:max-w-[300px]'}>
                                    <h1 className={'text-[17px] font-medium leading-[24px] max-w-[190px] text-[#777777] tracking-[4px] font-bold'}>{product.name.toUpperCase()}</h1>
                                    <div className={'flex flex-row justify-between items-end'}>
                                        <div className='input-hide-spin bg-white px-[10px] md:px-[20px] py-[5px] md:py-[10px] text-[#6D6662] w-[70px] md:w-[100px] flex items-center justify-between'>
                                            <button onClick={() => handleQuantityUpdate(product.id,  cart[product.id] ? cart[product.id][0]?.quantity - 1 : 0)}  disabled={cart[product.id] ? cart[product.id][0]?.quantity === 1 : 0}>-</button>
                                            <input
                                                type="number"
                                                value={cart[product.id] ? cart[product.id][0]?.quantity : 1}
                                                className="w-full text-center"
                                                readOnly
                                            />
                                            <button onClick={() => handleQuantityUpdate(product.id,  cart[product.id] ? cart[product.id][0]?.quantity + 1 : 0)}>+</button>
                                        </div>
                                        <span className={`${inter.className} text-[16px] mr-[-30px] text-cruxGreen font-black`}>
                                            <CustomNumberFormat value={parseFloat( product?.price && cart[product.id] ? product?.price*cart[product.id][0]?.quantity : 0).toFixed(2)}/>
                                        </span>
                                    </div>
                                </div>
                                <CancelIconGray onClick={() => handleRemoveItem(product.id)} />
                            </div>
                        ))}
                    </div>
                    <div className={'mb-[60px]'} >
                        <div className={'flex flex-col gap-[20px] border-t border-cruxBrown mx-[10px] mb-[20px] text-[#777777]'}>
                            <div className={'flex flex-col justify-between'}>
                                {
                                    products.map((product) => (
                                        <div key={product.id} className="flex flex-row justify-between my-[2px]">
                                            <p>{cart[product.id] ? cart[product.id][0].quantity : 0}x {product.name}</p>
                                            <div className={inter.className}>
                                                <CustomNumberFormat value={parseFloat( product?.price && cart[product.id] ? product?.price*cart[product.id][0]?.quantity : 0).toFixed(2)}/>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className={'flex flex-row justify-between mt-[2px]'}>
                                    <p>Popust</p>
                                    <div className={inter.className}>
                                        <CustomNumberFormat value={parseFloat(- discount || 0).toFixed(2)} />
                                    </div>
                                    {/*<p>-{(discount ? discount?.toFixed(2) : 0) + ' ' + 'RSD'}</p>*/}
                                </div>
                            </div>
                            <div className={'flex flex-row justify-between font-black'}>
                                <p>Ukupno</p>
                                <div className={'flex flex-col items-end text-cruxGreen text-[20px]'}>
                                    {discount > 0 ?
                                        <div className={`${inter.className} text-[#626261] text-[12px] font-[600] line-through`}>
                                            <CustomNumberFormat value={parseFloat(calculateTotal()+discount).toFixed(2)}/>
                                        </div> :
                                        null
                                    }
                                    <div className={inter.className}>
                                        <CustomNumberFormat value={parseFloat(calculateTotal()).toFixed(2)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button onClick={handeCashDesk} className={'bg-cruxGreen w-full text-white font-bold tracking-[1.6px] rounded-[30px]  py-[10px] items-center'}>BLAGAJNA</button>
                    </div>
                    </div>
                </div>
                :
                <div className={'flex flex-col h-full justify-between pt-[10px] pb-[50px] w-[320px] lg:w-full'}>
                    <div onClick={() => setIsCartModalOpen(false)} className={'flex justify-end z-50'}>
                        <CancelIcon/>
                    </div>
                    <div className={'text-[24px] text-[#777777] tracking-[6px] w-[250px] mx-auto 2xl:mb-[0] text-center'}>VAŠA KORPA JE TRENUTNO PRAZNA</div>
                    <button onClick={handleGoToStore}  className={'bg-cruxGreen text-[14px] xl:text-[16px] w-full text-white font-bold tracking-[1.6px] rounded-[30px] px-[40px] py-[16px] items-center'}>ISTRAŽITE CRUX PRIZVODE</button>
                </div>
                }
            </div>
        </div>
    );
};

export default CartPopUpModal;
