export const isEmpty = value =>
{
    return value === null || value === undefined || value === '';
}

export const isValidEmail = value =>
{
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(value);
}

export const isValidMobileNumber = value => {
    const mobileRegex = /^0\d{6,9}$/;
    return mobileRegex.test(value);
}


export const isValidNumber = value =>
{
    const numberRegex = /^[0-9]+$/;
    return numberRegex.test(value);
}

export const isEmptyObject = (obj) => {
    // Check if obj is not undefined or null
    if (obj === undefined || obj === null) {
        return true; // Assuming you want to consider undefined or null as an empty object
    }

    // Check if obj is an empty object
    return Object.keys(obj).length === 0;
};
